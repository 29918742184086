/*

NOTE: tag is not supported in horizontal menu

Array of object

Top level object can be:
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- nav Grp (top level grp is called header group) ---/

title
icon (if it's on top/second level)
children

/--- nav Item (top level item is called header link) ---/

icon (if it's on top/second level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)

*/
// import dashboard from './dashboard'
// import apps from './_apps'
// import pages from './pages'
// import chartsAndMaps from './charts-and-maps'
// import uiElements from './ui-elements'
// import formAndTable from './forms-and-table'
// import others from './others'
import patientInfo from "./patient-info"
import riskProcedure from "./risk-procedure"
import preOp from "./pre-op"
import intraOp from "./intra-op"
import postOp from "./post-op"
import discharge from "./discharge"
import followUp from "./follow-up"

// Array of sections
export default [...patientInfo, ...riskProcedure, ...preOp, ...intraOp, ...postOp, ...discharge, ...followUp]
